import { defineStore } from 'pinia'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { useNuxtApp } from '#app'
import { getAddresLocation } from '~/composible/getAddresLoc'
import type { AddressChain } from '~/api'

interface requestParamsInt {
  yandex_geo?: number
  google_geo?: number
  client_ip?: string
}
export const useCities = defineStore('cities', {
  state: () => ({
    currentCity: {},
    currentRegion: {},
    redirectObj: undefined as {} | undefined,
    streetPageLoading: false,
    softNoindex: null as boolean | null,
    requestParams: {} as requestParamsInt,
    needGetCity: false,
    allCities: null,
    allRegions: null,
    indexTopologies: [] as string[],
    house: {},
    addressAncestors: undefined as AddressChain | undefined,
  }),
  getters: {
    getRedirectObj: state => state.redirectObj,
    getCity: (state) => {
      let city = state.addressAncestors?.address_objects?.find(el => el.level === 'city')
      if (!city) city = state.addressAncestors?.address_objects?.find(el => el.level === 'street')
      return city
    },
    getDataStreet: (state) => {
      let street
      const city = state.addressAncestors?.address_objects?.find(el => el.level === 'city')
      if (city) street = state.addressAncestors?.address_objects?.find(el => el.level === 'street')
      return street
    },
    getRegion: state => state.currentRegion,
    getCertainty: state => state.addressAncestors?.certainty,
    getRequestParams: state => state.requestParams,
    getNeedGetCity: state => state.needGetCity,
    getSoftNoindex: state => state.softNoindex,
    getStreetPageLoading: state => state.streetPageLoading,
    getAllCities: state => state.allCities,
    getAllRegions: state => state.allRegions,
    getIndexTopologies: state => state.indexTopologies,
    getHouse: state => state.house,
    getHouseId: state => state.house?.houseId,
    getHouseUrl: state => state.house?.autoGeneratedUrl,
    getHouseHash: state => state.house?.hash,
    getHouseType: state => state.house?.houseType,
    getAddressAncestors: state => state.addressAncestors,
    getAddressText: (state) => {
      const indexRoot = state.addressAncestors?.address_objects?.find(
        el => el.url_root,
      )
        ? state.addressAncestors.address_objects.indexOf(
          state.addressAncestors.address_objects.find(
            el => el.url_root,
          )!,
        ) + 1
        : state.addressAncestors?.address_objects.length || undefined
      const currentArea = state.addressAncestors?.address_objects?.find(
        el => el.level === 'region',
      )
      const cityIndex = state.addressAncestors?.address_objects?.find(
        el => el.level === 'city',
      )
        ? state.addressAncestors.address_objects.indexOf(
          state.addressAncestors.address_objects.find(
            el => el.level === 'city',
          )!,
        )
        : state.addressAncestors?.address_objects?.find(
          el => el.level === 'street',
        )
          ? state.addressAncestors.address_objects.indexOf(
            state.addressAncestors.address_objects.find(
              el => el.level === 'street',
            )!,
          )
          : state.addressAncestors?.address_objects?.length || undefined

      const addressObjects
        = state.addressAncestors?.address_objects
          .slice(0, indexRoot)
          .reverse()
          ?.filter(el => el.level !== 'area')
          .map(
            el =>
              (el.short_name && typeof el.short_name === 'string'
                ? el.short_name.replace(/-/g, '\u2011') + '\u00A0'
                : '') + (el.name ? el.name.replace(/-/g, '\u2011') : ''),
          ) || []
      const addressObjectsStreets
        = state.addressAncestors?.address_objects
          .slice(0)
          .reverse()
          ?.filter(el => el.level === 'street')
          .map(
            el =>
              (el.short_name && typeof el.short_name === 'string'
                ? el.short_name.replace(/-/g, '\u2011') + '\u00A0'
                : '') + (el.name ? el.name.replace(/-/g, '\u2011') : ''),
          ) || []

      const cityUrl = useCities().getCity?.url || ''

      const house = state.house?.autoGeneratedUrl ? state.house : ''

      const fullAddress
        = addressObjects?.join(', ')
        + (house ? ', ' + house.houseNumber.replace(/-/g, '\u2011') : '')
      const street = addressObjects?.join(', ')
      const streetOnly = addressObjectsStreets.join(', ')
      const houseOnly = house?.houseNumber?.replace(/-/g, '\u2011')

      const houseAddress
        = state.addressAncestors?.address_objects
          .slice(0, cityIndex || 1)
          .reverse()
          .map(
            el =>
              (el.short_name && typeof el.short_name === 'string'
                ? el.short_name.replace(/-/g, '\u2011') + '\u00A0'
                : '') + (el.name ? el.name.replace(/-/g, '\u2011') : ''),
          )
          .join(', ')
          + (house ? ', ' + house.houseNumber.replace(/-/g, '\u2011') : '')

      const cityObjects = state.addressAncestors?.address_objects
        .slice(cityIndex ? cityIndex + 1 : 1, indexRoot)
        .reverse()
        ?.filter(el => el.level !== 'area')
      const cityAddress = addressObjects
        ? addressObjects
          ?.filter(el => el.level !== 'area')
          .map(
            el =>
              (el.short_name && typeof el.short_name === 'string'
                ? el.short_name.replace(/-/g, '\u2011') + '\u00A0'
                : '') + (el.name ? el.name.replace(/-/g, '\u2011') : ''),
          )
          .join(', ')
        : ''
      const cityArea = cityObjects?.length
        ? ' '
        + cityObjects.map(
          el =>
            (el.short_name && typeof el.short_name === 'string'
              ? el.short_name.replace(/-/g, '\u2011') + '\u00A0'
              : '') + (el.name ? el.name.replace(/-/g, '\u2011') : ''),
        )
        : ''

      const currentCity = state.addressAncestors?.address_objects.slice(
        cityIndex,
        indexRoot,
      )?.[0]
      const cityOnly = currentCity
        ? (cityObjects?.length
            ? cityObjects.map(el =>
              el.short_name && typeof el.short_name === 'string'
                ? el.short_name.replace(/-/g, '\u2011') + '\u00A0'
                : '',
            )
            : '')
            + currentCity?.name.replace(/\s/g, '\u00A0').replace(/-/g, '\u2011')
        : ''
      const areaOnly = currentArea ? currentArea.full_name : cityOnly
      const cityOnlyLoc
        = currentCity && currentCity?.morph_name?.['где']
          ? currentCity?.morph_name?.['где']
            ?.replace(/\s/g, '\u00A0')
            .replace(/-/g, '\u2011') + cityArea
          : currentCity
            ? (currentCity?.short_name && !currentCity?.name_loc
                ? currentCity?.short_name + ' '
                : '')
                + (currentCity?.name_loc
                  ? currentCity?.name_loc
                    .replace(/\s/g, '\u00A0')
                    .replace(/-/g, '\u2011') + cityArea
                  : currentCity?.name
                    .replace(/\s/g, '\u00A0')
                    .replace(/-/g, '\u2011'))
            : ''
      const areaOnlyLoc = currentArea?.morph_name?.['где']
        ? currentArea?.morph_name?.['где']
          ?.replace(/\s/g, '\u00A0')
          .replace(/-/g, '\u2011')
        : cityOnlyLoc
      const cityOnlyPos
        = currentCity && currentCity?.morph_name?.['Р']
          ? currentCity?.morph_name?.['Р']
            ?.replace(/\s/g, '\u00A0')
            .replace(/-/g, '\u2011') + cityArea
          : currentCity
            ? (currentCity?.short_name && !currentCity?.name_loc
                ? currentCity?.short_name + ' '
                : '')
                + (currentCity?.name_loc
                  ? currentCity?.name_loc
                    .replace(/\s/g, '\u00A0')
                    .replace(/-/g, '\u2011') + cityArea
                  : currentCity?.name
                    .replace(/\s/g, '\u00A0')
                    .replace(/-/g, '\u2011'))
            : ''
      const areaOnlyPos = currentArea?.morph_name?.['Р']
        ? currentArea?.morph_name?.['Р']
          ?.replace(/\s/g, '\u00A0')
          .replace(/-/g, '\u2011')
        : cityOnlyPos

      return {
        cityIndex,
        indexRoot,
        currentArea,
        areaOnly,
        areaOnlyLoc,
        areaOnlyPos,
        cityArea,
        cityObjects,
        houseAddress,
        house,
        fullAddress,
        addressObjects,
        cityAddress,
        street,
        streetOnly,
        houseOnly,
        cityOnly,
        cityOnlyLoc,
        cityOnlyPos,
        cityUrl,
        currentCity,
      }
    },
  },
  actions: {
    async getAncestorsChainForAddress(fiasId: string) {
      const mainStore = useMainStore()
      const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
      try {
        const response = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${fiasId}/ancestors/`, {
          method: 'GET',
          headers: {
            ...getCommonHeaders.value,
          },
          params: {
            include_self: true,
            morph: true,
            site_id: import.meta.env.VITE_SITE_ID,
          },
        })

        return this.getAddressObjectsFromResponse(response)
      }
      catch (e) {
        throw this.createErrorFromResponse(e)
      }
    },

    getAddressObjectsFromResponse(response) {
      let street
      let city = this.findAddressObject(response, 'city')
      if (
        !city
        && response?.address_objects?.filter(el => el.level === 'street')
          ?.length > 1
      ) {
        city = response?.address_objects
          .slice()
          .reverse()
          .find(el => el.level === 'street')
        street = response?.address_objects.find(
          address => address.level === 'street',
        )
      }
      else if (!city) city = this.findAddressObject(response, 'street')
      else street = this.findAddressObject(response, 'street')

      this.indexTopologies = response.indexed_topologies

      return { city, street, res: response }
    },

    findAddressObject(response, level: string) {
      return response?.address_objects.find(el => el.level === level)
    },

    createErrorFromResponse(error) {
      return createError({
        statusCode: error.response.status,
        fatal: true,
        message: error.message,
      })
    },

    async nuxtServerInit($route: any) {
      try {
        this.redirectObj = undefined
        const provider = process.env.PROVIDER_SLUG
        const providerId = process.env.PROVIDER_ID ? +process.env.PROVIDER_ID : undefined
        const tariffsStore = useTariffs()
        const mainStore = useMainStore()
        const $nuxt = useNuxtApp()
        const getBranch = import.meta.env.VITE_BRANCH

        if ($route.path[$route.path.length - 1] !== '/') {
          throw createError({
            statusCode: 404,
            fatal: true,
            message: 'not found',
          })
        }

        const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
        const cityFromCookie = useCookie('currentCity')
        const utmArrCookie = useCookie('utmArr')
        const addressFromCookie = useCookie('currentAddress')
        const abTestCookie = useCookie('ab_test')
        const sessionidCookie = useCookie('sessionid')
        const { 'x-real-ip': xRealIp } = useRequestHeaders()
        const reqHeaders = useRequestHeaders()
        const {
          'x-ab-test': abTest,
          host,
          'x-forwarded-host': newHost,
          'x-utm-state': xUtm,
          'if-modified-since': ifModifiedSince,
          'user-agent': userAgent,
          referer,
          cookie: ssrCookie,
        } = useRequestHeaders()
        const domain = newHost || (host === 'localhost:8080' ? process.env.MAIN_DOMAIN : host)
        mainStore.mainDomain = process.env.MAIN_DOMAIN
        mainStore.domain = domain
        mainStore.reqHeaders = reqHeaders
        const redirect = $nuxt.ssrContext?.event.res
        const partnerId = useCookie('partner_id')

        const numberPromise = async (val?: number) => {
          await mainStore.fetchPhoneNumber(val)
        }

        let respSiteOptions: any
        const respPromise = async () => {
          try {
            respSiteOptions = await $fetch('https://' + domain + '/api/site-options/', {
              method: 'GET',
              headers: {
                'authorization': 'Bearer ' + process.env.AUTH_TOKEN,
                'if-modified-since': ifModifiedSince,
                'X-Utm-State': xUtm,
                'user-agent': userAgent,
                referer,
              },
              params: {
                url: 'https://' + domain + $route.fullPath,
                branch: getBranch,
                site_version: abTest || abTestCookie.value || undefined,
              },
            })
          }
          catch (e) {
            console.log(e)
            return
            // Обработка ошибки
          }
        }

        mainStore.$patch({
          disabledPortal: $nuxt.$device.isDesktop,
          disabledMobile: $nuxt.$device.isDesktop,
        })

        const queries = new URLSearchParams($route.fullPath.split('?')[1])
        const utmArr = [] as any[]
        for (const query of queries.entries()) {
          if (
            query[0] === 'deviceuse__device'
            || query[0] === 'tv_box_count'
            || query[0] === 'service__service_type'
            || query[0] === 'sort'
          ) {
            this.redirectObj = {
              url: $route.path,
            }
            return
          }
          if (query[0] === 'yandex_geo')
            this.requestParams.yandex_geo = +query[1] || undefined
          if (query[0] === 'google_geo')
            this.requestParams.google_geo = +query[1] || undefined
          if (query[0] === 'partner_id') partnerId.value = query[1]
          if (query[0].includes('utm')) {
            utmArr.push(query)
          }
        }
        if (utmArr.length) {
          mainStore.$patch({
            utmArr,
          })
        }
        else if (utmArrCookie.value) {
          mainStore.$patch({
            utmArr: utmArrCookie.value,
          })
        }

        if (xRealIp) {
          this.requestParams.client_ip = xRealIp
          mainStore.$patch({
            clientIp: xRealIp,
          })
        }

        const diNotProviderCookie = useCookie('diNotProvider')
        const oldClientCookie = useCookie('oldClient')
        const providerClientCookie = useCookie('providerClient')
        const addressWOTariffsCookie = useCookie('addressWOTariffs')

        if ($route.name === 'quiz-complete') {
          this.redirectObj = {
            url: '/quiz/',
            code: 301,
          }
          return
        }
        else if (
          (provider && $route.path.includes('tariffs/')
            && $route.name !== 'city-tariffs-id')
            || (/\/providers\/[^/]*\/tariffs\//i.test($route.path)
              && $route.name !== 'city-providers-provider-tariffs-id'
            )
        ) {
          const url = $route.fullPath.replace(/\/tariffs/gi, '')
          this.redirectObj = {
            url: url,
            code: 301,
          }
        }
        else if (
          (/providers-provider.*/i.test($route.name)
            && $route.name !== 'city-providers-provider-tariffs-id'
            && !diNotProviderCookie.value)
        ) {
          mainStore.$patch({
            diProvider: true,
          })
        }

        const localRoute = ('/' + ($route.params.city === '' ? '' : $route.params.city?.filter(el => !!el).join('/') || '') + ($route.params.street ? '/' + $route.params.street : '')).replace(/^\/tariffs.*|^\/house-tariffs.*|^\/reviews.*|^\/for-house.*/, '/')

        let loc: any
        const { getAddresLoc } = getAddresLocation()
        const promiseLoc = async () => {
          loc = await getAddresLoc({
            url: $route.params['slug'] && $route.name === 'personal-address-slug'
              ? $route.params['slug']
                .filter((el: string) => !!el)
                .join('/')
              : $route.query.houseUrl
                ? $route.query.houseUrl
                : !$route.params['slug'] ? addressFromCookie.value || undefined : undefined,
            houseUrl: $route.params['slug'] && $route.name === 'city-address-slug'
              ? $route.params['city'].join('/') + '/' + $route.params['slug'].replace(/-dom-/g, '/')
              : undefined,
          })
        }
        await Promise.all([promiseLoc(), respPromise(), numberPromise(providerId || 0)])

        if (respSiteOptions?.last_modified) {
          redirect?.setHeader(
            'Last-Modified',
            new Date(respSiteOptions?.last_modified).toUTCString(),
          )
        }
        if (respSiteOptions?.not_modified) {
          this.redirectObj = {
            url: $route.fullPath,
            code: 304,
          }
          return
        }

        if (sessionidCookie.value) {
          mainStore.$patch({
            commonHeaders: {
              'ip': xRealIp || null,
              'X-Sessionid': sessionidCookie.value,
              'X-Forwarded-Host': host,
              'X-Utm-State': xUtm,
              'cookie': ssrCookie,
            },
          })
        }
        else {
          mainStore.$patch({
            commonHeaders: {
              'ip': xRealIp || null,
              'X-Forwarded-Host': host,
              'X-Utm-State': xUtm,
              'cookie': ssrCookie,
            },
          })
        }

        const config = respSiteOptions?.options.config as any

        mainStore.$patch({
          oldClient: !!oldClientCookie.value || false,
          providerClient: providerClientCookie.value || undefined,
          addressWOTariffs: !!addressWOTariffsCookie.value || false,
          configShowMap: config?.show_map,
          automation: config?.automation,
          quizBtnShow: config?.quiz_btn_show,
          btn60Show: config?.btn_60sec || config?.btn60sec || config?.btn60sec_test,
          loaderText: config?.loader_text,
          loaderTime: config?.loader_time,
          segmentation: config?.segmentation || false,
          woFilter: config?.address_wo_filter,
          saWStreets: !!config?.sa_w_streets,
          domainConfig: provider ? respSiteOptions?.options : undefined,
          metrikaId: respSiteOptions?.options?.yandex_id,
          uaId: respSiteOptions?.options?.google_id,
          providerId: providerId ? providerId + '' : undefined,
          woConsult: config?.wo_consult,
          hideNumber: config?.hide_phone_number,
          automatizationDirect: config?.automatization_direct,
          reverseAutomatizationTabs: config?.reverse_automatization_tabs,
          showGreenBtn: config?.show_green_btn,
          popularSortVersion: config?.popular_sort_version,
          hideConnectionTime: config?.hide_connection_time,
          test22: config?.test_22,
        })

        if (loc?.houseId || loc?.houseFiasId || (loc?.streetFiasId && loc?.houseNumber)) {
          const { city, res }
            = await this.getAncestorsChainForAddress(
              loc.fiasPath[loc.fiasPath.length - 1],
            )
          if (res?.redirect_status_code) {
            const oldUrl = $route.fullPath
            this.redirectObj = {
              url: oldUrl.replace(
                localRoute.slice(1),
                res.house?.url || res.address_objects[0].url,
              ),
              code: res.redirect_status_code,
            }
          }

          if ((this.house?.fiasPath && !this.house?.fiasPath?.includes(city.fias_id)) || (localRoute?.length > 2 && !localRoute.includes(city.url))) {
            this.house = {}
            tariffsStore.passThroughProviderId = null
            const responce = await $fetch(`https://${domain}/api/locations/address-objects/chain-by-geo/`, {
              method: 'GET',
              headers: {
                ...getCommonHeaders.value,
              },
              params: {
                with_available_smart_filters: false,
                morph: true,
                url_geo: localRoute.replace(/^\/undefined/, ''),
                site_id: import.meta.env.VITE_SITE_ID,
                ...this.requestParams,
              },
            })

            const { res } = this.getAddressObjectsFromResponse(responce)
            this.$patch({
              addressAncestors: res,
            })

            return
          }

          this.$patch({
            addressAncestors: res,
          })

          if (!provider && /^\/house-tariffs\//i.test($route.path)) {
            this.redirectObj = {
              url: `/personal/address/${loc.autoGeneratedUrl}/`,
              code: 302,
            }
          }

          return
        }
        else if (localRoute?.length > 1) {
          let responce
          let responceCheck
          try {
            responceCheck = await $fetch(`https://${domain}/api/locations/address-objects/chain-by-geo/`, {
              method: 'GET',
              headers: {
                ...getCommonHeaders.value,
              },
              params: {
                with_available_smart_filters: false,
                morph: true,
                url_geo: localRoute.replace(/^\/undefined/, ''),
                site_id: import.meta.env.VITE_SITE_ID,
                ...this.requestParams,
              },
            })
          }
          catch (e) {
            console.log(e)
          }

          if (
            responceCheck.address_objects.length === 1
            && responceCheck.address_objects[0].level === 'region'
          ) {
            this.currentRegion = responceCheck.address_objects[0]
            if (this.house?.fiasPath && !this.house?.fiasPath?.includes(city.fias_id)) {
              this.house = {}
              tariffsStore.passThroughProviderId = null
            }
            if (
              (cityFromCookie.value)
            ) {
              const { res } = await this.getAncestorsChainForAddress(
                cityFromCookie.value,
              )
              this.$patch({
                addressAncestors: res,
              })
            }
            return
          }
          else {
            responce = responceCheck
          }
          if (responce?.redirect_status_code) {
            const oldUrl = $route.fullPath
            this.redirectObj = {
              url: oldUrl.replace(
                localRoute.slice(1),
                responce.house?.url || responce.address_objects[0].url,
              ),
              code: responce?.redirect_status_code,
            }
          }
          const { city, res } = this.getAddressObjectsFromResponse(responce)

          if (responce.house) {
            throw createError({
              statusCode: 410,
              fatal: true,
              message: 'not found',
            })
          }

          this.$patch({
            addressAncestors: res,
          })
          if (this.house?.fiasPath && !this.house?.fiasPath?.includes(city.fias_id)) {
            this.house = {}
            tariffsStore.passThroughProviderId = null
          }

          return
        }
        else if (
          cityFromCookie.value
        ) {
          const { city, res } = await this.getAncestorsChainForAddress(
            cityFromCookie.value,
          )
          this.$patch({
            addressAncestors: res,
          })
          if (/^\/tariffs.*|^\/house-tariffs.*|^\/reviews.*|^\/for-house.*/.test($route.path)) {
            let url = `/${city!.url}${$route.fullPath.replace(
              '/house-tariffs/',
              '/tariffs/',
            )}`
            if (provider) {
              url = url.replace(/\/tariffs/gi, '')
            }
            this.redirectObj = {
              url,
              code: 302,
            }
          }
          return
        }
        else if (this.requestParams.yandex_geo || this.requestParams.google_geo || /^\/tariffs.*|^\/house-tariffs.*|^\/streets.*|^\/reviews.*|^\/for-house.*/.test($route.path)) {
          const responce = await $fetch(`https://${domain}/api/locations/address-objects/chain-by-geo/`, {
            method: 'GET',
            headers: {
              ...getCommonHeaders.value,
            },
            params: {
              with_available_smart_filters: false,
              morph: true,
              provider: providerId || $route.params.provider,
              site_id: import.meta.env.VITE_SITE_ID,
              ...this.requestParams,
            },
          })

          const { city } = this.getAddressObjectsFromResponse(responce)
          let url = `/${city!.url}${$route.fullPath.replace(
            '/house-tariffs/',
            '/tariffs/',
          )}`
          if (provider) {
            url = url.replace(/\/tariffs/gi, '')
          }
          this.redirectObj = {
            url,
            code: 302,
          }

          return
        }
        else {
          this.needGetCity = true
          return
        }
      }
      catch (e) {
        console.log('catch this')
        console.log(e)
        throw createError({
          statusCode: e.statusCode === 410 ? 410 : 404,
          fatal: true,
          message: 'not found',
        })
      }
    },
  },
})
